<template>
  <v-container
    id="widgets"
    fluid
    tag="section"
    class="pt-0"
  >
    <!-- Таблица Отчетов START -->
    <base-material-card
      color="success"
      inline
      class="px-4 py-3"
      filter
    >
      <template #heading>
        <div class="text-h3 font-weight-light">
          <v-icon>
            mdi-file-document-box-multiple-outline
          </v-icon>
          Недельный план/факт ({{ dateFrom }})
        </div>
      </template>
      <template #after-heading>
        <v-select
          v-model="DossiersOfficesSelect"
          class="pr-2"
          label="Подразделение:"
          :items="DossiersOffices"
          item-text="dossiersofficetitle"
          item-value="dossierofficeid"
          @change="updateData"
        />
        <v-menu
          ref="menuFrom"
          v-model="menuFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="dateFromF"
              persistent-hint
              @blur="parseDate(dateFromF)"
              v-on="on"
            >
              <span
                slot="prepend"
                class="flex text-h4 font-weight-light"
              >с
              </span>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            no-title
            locale="ru-ru"
            @input="updateDay()"
          />
        </v-menu>
      </template>
      <v-progress-linear
        v-if="loadingData"
        indeterminate
        color="yellow darken-2"
        class="pa-2 my-6"
      />
      <v-row
        v-else
        class="mt-0"
      >
        <v-col
          v-for="(day, index) in Performs"
          :key="'days-' + index"
        >
          <v-form
            v-model="valid[index]"
            class="text-center"
          >
            <v-hover
              v-slot="{ hover }"
            >
              <v-card
                class="pa-5 mt-3 pb-0 grey rounded-lg "
                :class="index>4?'lighten-2':'lighten-5'"
                style="position: relative"
                :elevation="hover ? 12 : 2"
              >
                <div class="top-card-title">
                  {{ formatDate(index) }}
                </div>
                <div class="ma-1" />
                <template v-for="(item, i) in day">
                  <v-subheader
                    :key="'subtile-' + i"
                    style="height: auto; padding-left: 0px; margin-bottom: 6px"
                  >
                    {{ item[0] }}
                  </v-subheader>
                  <v-row :key="'input-def-' + i + '-' + index">
                    <v-col class="pr-1">
                      <v-text-field
                        :ref="'input-1-' + i + '-' + index"
                        v-model="item[1][1].pvalue"
                        label="план"
                        dense
                        readonly
                        @keyup.enter="setFocusOnEnter('1', i, index)"
                      />
                    </v-col>
                    <v-col class="pb-0 pl-0">
                      <v-text-field
                        :ref="'input-2-' + i + '-' + index"
                        v-model="item[1][0].pvalue"
                        label="факт"
                        solo
                        dense
                        @input="clearError(i, index, $event)"
                        @keyup.enter="setFocusOnEnter('2', i, index)"
                      />
                    </v-col>
                  </v-row>
                </template>
                <v-card-actions>
                  <v-btn
                    class="my-2 ml-auto mr-0"
                    icon
                    :loading="savingNewPerforms"
                    :disabled="performsChanged(index)"
                    @click="saveNewPerforms(index)"
                  >
                    <v-icon>
                      mdi-content-save-outline
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-form>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
  export default {
    name: 'PerDayPerform',
    data () {
      return {
        cantUpdate: false,
        loadingData: false,
        savingData: false,
        name: null,
        valid: {},
        dateFromF: null,
        dateFrom: null,
        menuFrom: false,
        DossiersOffices: [],
        DossiersOfficesSelect: null,
        Performs: [],
        PerformsInit: [],
        rulesRequire: [
          v => !!v || 'Необходимо заполнить',
        ],
        workshop: [],
        savingNewPerforms: false,
      }
    },
    computed: {
      weekDays () {
        const week = []
        // Starting Monday not Sunday
        const current = new Date(this.dateFrom)
        current.setDate((current.getDate() - current.getDay() + 1))
        for (let i = 0; i < 7; i++) {
          week.push(
            new Date(current).toISOString().split('T')[0]
          )
          current.setDate(current.getDate() + 1)
        }
        return week
      },
    },
    watch: {
      dateFrom (val) {
        this.dateFromF = this.formatDate(this.dateFrom)
        if (this.dateFrom < this.dateTo) {
          this.$store.dispatch('getTally', { dateTo: this.dateTo, dateFrom: this.dateFrom }).then((resp) => {
            this.Tallys = resp
            this.selectedTally = null
          }).catch((e) => {
            console.log(e)
          })
        }
      },
    },
    mounted () {
      this.loadingData = true
      const d = new Date()
      const week = this.getWeekDays(d)
      const dateMinus = 0
      this.dateTo = new Date(week[6]).toISOString().substr(0, 10)
      this.dateToF = this.formatDate(this.dateTo)
      d.setDate(d.getDate() - dateMinus)
      this.dateFrom = new Date(week[0]).toISOString().substr(0, 10)
      this.dateFromF = this.formatDate(this.dateFrom)
      this.$store.dispatch('getDossiersOffices').then((x) => {
        this.DossiersOffices = x
        this.DossiersOfficesSelect = x[0].dossierofficeid
        this.DossiersOfficesFilter = x[0].dossiersofficetitle
        this.$store.dispatch('getSPFperformance', {
          dateTo: this.dateTo,
          dateFrom: this.dateFrom,
          dossierofficeid: this.DossiersOfficesSelect,
          ptype: 3,
        }).then((resp) => {
          const wsTypes = []
          resp.forEach((x) => {
            if (x.ptype === 3 && x.pvalue) {
              this.cantUpdate = true
            }
            wsTypes.push({ workshop: x.workshop, wstitle: x.wstitle })
          })
          this.workshop = [...new Set(wsTypes.map((o) => JSON.stringify(o)))].map((strings) => JSON.parse(strings))
          this.Performs = this.groupResponse(resp, 'datestr')
          for (const day in this.Performs) {
            this.Performs[day] = Object.entries(this.groupResponse(this.Performs[day], 'wstitle'))
          }
        }).catch((e) => {
          console.log(e)
        }).then(() => {
          this.PerformsInit = JSON.parse(JSON.stringify(this.Performs))
          this.loadingData = false
        })
      }).catch((e) => {
        console.log(e)
      })
    },
    methods: {
      clearError (i, index, event) {
        if (event) {
          this.$nextTick(() => {
            const refName = 'input-2-' + i + '-' + index
            this.$refs[refName][0].errorMessages.pop()
            this.Performs[index][i][1][0].pvalue = event.replace(/\D/g, '')
          })
        }
      },
      performsChanged (col) {
        return JSON.stringify(this.Performs[col]) === JSON.stringify(this.PerformsInit[col])
      },
      saveNewPerforms (col) {
        this.savingNewPerforms = true
        const emptyFields = []
        const newFields = []
        this.Performs[col].forEach((wsValue, idx) => {
          if (wsValue[1][0].pvalue === null || wsValue[1][0].pvalue === '') {
            emptyFields.push(idx)
          } else {
            newFields.push(wsValue[1][0])
          }
        })
        if (emptyFields.length > 0) {
          const refName = 'input-2-' + emptyFields[0] + '-' + col
          this.$nextTick(() => {
            this.$refs[refName][0].focus()
            this.$refs[refName][0].errorMessages.push('!пусто')
          })
          this.savingNewPerforms = false
        } else {
          this.$store.dispatch('peformPerDayChange', { pload: JSON.stringify(newFields), type: 3 }).then((resp) => {
            console.log('aaa', resp)
            const minusItem = resp.filter((val) => val < 0)
            console.log('minuuuus', minusItem)
            if (minusItem.length > 0) {
              this.$store.dispatch('setSnackbar', { state: true, text: 'Ошибка добавления.', color: 'info', direction: 'top center' })
            } else {
              this.cantUpdate = false
              this.PerformsInit = JSON.parse(JSON.stringify(this.Performs))
              this.$store.dispatch('setSnackbar', { state: true, text: 'Данные сохранены.', color: 'success', direction: 'top center' })
            }
          }).catch((e) => {
            console.log('Error', e)
            this.savingData = false
          })
          this.savingNewPerforms = false
        }
      },
      groupResponse (resp, key) {
        return resp.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {})
      },
      getWeekDays (current) {
        const week = []
        // Starting Monday not Sunday
        current = new Date(current)
        current.setDate((current.getDate() - current.getDay() + 1))
        for (let i = 0; i < 7; i++) {
          week.push(
            new Date(current).toISOString().split('T')[0]
          )
          current.setDate(current.getDate() + 1)
        }
        return week
      },
      updateData () {
        this.loadingData = true
        this.Performs = []
        this.cantUpdate = false
        const d = new Date(this.dateFrom)
        const day = d.getDay()
        const diff = d.getDate() - day + (day == 0 ? -6 : 1)
        const dateFrom = new Date(d.setDate(diff)).toISOString().substr(0, 10)
        const dayTo = d.getDate() + 6
        console.log(`diff: ${diff} day:${day} dayTo:${dayTo}`)
        const dateTo = new Date(d.setDate(dayTo)).toISOString().substr(0, 10)
        // const pdateFrom = this.parseDate(dateFrom)
        // const pdateTo = this.parseDate(dateTo)
        console.log(`date from: ${dateFrom} date to:${dateTo}`)
        this.$store.dispatch('getSPFperformance', {
          dateTo: dateTo,
          dateFrom: dateFrom,
          dossierofficeid: this.DossiersOfficesSelect,
          ptype: 3,
        }).then((resp) => {
          resp.forEach((x) => {
            if (x.ptype === 3 && x.pvalue) {
              this.cantUpdate = true
            }
          })
          this.Performs = this.groupResponse(resp, 'datestr')
          for (const day in this.Performs) {
            this.Performs[day] = Object.entries(this.groupResponse(this.Performs[day], 'wstitle'))
          }
          this.loadingData = false
          // this.Performs = resp
        }).catch((e) => {
          console.log(e)
        })
      },
      setFocusOnEnter (col, i, index) {
        const netxItem = i + 1
        const refName = 'input-' + col + '-' + netxItem + '-' + index
        if (this.Performs[index].length > netxItem) {
          this.$nextTick(() => this.$refs[refName][0].focus())
        } else {
          if (this.valid[index] && !this.savingNewPerforms && !this.performsChanged(index)) {
            this.saveNewPerforms(index)
          }
        }
      },
      updateDay () {
        this.menuFrom = false
        this.updateData()
      },
      getRandomInt (max) {
        return Math.floor(Math.random() * Math.floor(max))
      },
      formatDate (date) {
        console.log(date)
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      parseDate (date) {
        if (!date) return null
        const [day, month, year] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    },
  }
</script>

<style>
  .top-card-title {
    position: absolute;
    top: -10px;
    left: 17px;
    color: #2d2828;
    background-color: #eed8cc;
    padding: 2px 6px;
    border-radius: 4px!important;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }
</style>
